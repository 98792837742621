<template>
  <div>
    <template v-for="(payment, pIndex) in payments">
      <template v-if="pIndex !== 0">&middot;</template>
      <router-link
        :class="pIndex === 0 ? 'mr-2' : 'mx-2'"
        :key="pIndex"
        :to="{
          name: 'PaymentDataView',
          params: { id: item.id, year: payment.year },
        }"
        >{{ payment.year }}</router-link
      >
    </template>
    <v-btn @click="expanded = true" icon v-if="expandable && !expanded">
      <v-icon>more_horiz</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'PaymentYearListing',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    }
  },
  computed: {
    expandable() {
      return this.item.payments.length > 10
    },
    payments() {
      const payments = [...this.item.payments].sort((a, b) => b.year - a.year)
      if (this.expanded) {
        return payments
      }
      return payments.slice(0, 10)
    },
  },
}
</script>

<style scoped></style>
