<template>
  <v-layout justify-end>
    <v-menu bottom left>
      <v-btn
        :color="currentCompany.main_color"
        :dark="dark"
        class="style-switcher"
        slot="activator"
      >
        {{ currentCompany && currentCompany.name }}
        <v-icon>keyboard_arrow_down</v-icon>
      </v-btn>
      <v-list>
        <v-list-tile
          :key="company.id"
          @click="setCurrentCompany(company.id)"
          v-for="company in companies"
        >
          <v-list-tile-title>{{ company.name }}</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CompanySelector',
  computed: {
    ...mapGetters(['companies', 'currentCompany']),

    dark() {
      const currentColor = this.$store.getters.currentCompanyRGB
      const ratio = contrast(currentColor, [255, 255, 255])
      return ratio > 5
    },
  },
  methods: {
    ...mapActions(['setCurrentCompany']),
  },
}

function luminanace(r, g, b) {
  let a = [r, g, b].map(function (value) {
    const v = value / 255
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
  })
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
}

function contrast(rgb1, rgb2) {
  const lum1 = luminanace(rgb1[0], rgb1[1], rgb1[2])
  const lum2 = luminanace(rgb2[0], rgb2[1], rgb2[2])
  const brightest = Math.max(lum1, lum2)
  const darkest = Math.min(lum1, lum2)
  return (brightest + 0.05) / (darkest + 0.05)
}
</script>

<style scoped></style>
