import BaseApi from './BaseApi'

class PaymentDataApi extends BaseApi {
  getAll(page) {
    return this.get(`paymentData/all?page=${page}`)
  }

  searchEntries(company_id, search, page = 1) {
    const params = {
      company_id,
      page,
    }
    if (search) {
      params.search = search
    }
    return this.get(`/paymentData/search`, params)
  }

  getEntry(ags) {
    return this.get(`/zahlungsDatenByAgs/${ags}`)
  }

  getList(company) {
    return this.get(`zahlung/${company}`)
  }

  updateEntry(payment) {
    const id = payment.id
    return this.put(`paymentData/${id}`, payment)
  }

  addNote(id, text) {
    return this.post(`paymentData/${id}/addNote`, { text })
  }

  addPaymentLineNote(id, text) {
    return this.post(`paymentLines/${id}/addNote`, { text })
  }

  getStatusStats(companyId) {
    return this.get(`companies/${companyId}/paymentData/statusStats`)
  }

  getStatusStatsEntries(
    company_id,
    type,
    year,
    page,
    orderBy,
    orderDirection,
    search
  ) {
    const params = {
      type,
      year,
      page,
      orderBy,
      orderDirection,
      search: search || null,
    }
    return this.get(
      `companies/${company_id}/paymentData/statusStats/entries/`,
      params
    )
  }

  createPaymentYear(masterDataId, year, ihkName, municipalRate) {
    return this.post(
      `masterData/${masterDataId}/paymentData/createPaymentYear`,
      {
        year,
        ihkName,
        municipalRate,
      }
    )
  }

  updateBusinessTax(paymentDataId, businessTax) {
    return this.post(`paymentData/${paymentDataId}/setBusinessTax`, {
      businessTax,
    })
  }

  savePaymentLines(payment_lines) {
    return this.post(`paymentLines/storeMultiple`, { payment_lines })
  }
}

export default new PaymentDataApi()
