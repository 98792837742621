<template>
  <v-app :style="style" id="app">
    <v-toolbar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      color="primary"
      dark
      app
      fixed
      tabs
    >
      <router-link
        :to="{ name: 'Dashboard' }"
        class="mr-3 headline white--text"
        style="user-select: none; text-decoration: none"
        >INGEWA
      </router-link>
      <v-tabs class="hidden-sm-and-down" color="primary" slider-color="accent">
        <template v-for="(item, index) in mainMenuEntries">
          <v-tab
            :key="index"
            v-if="allow(item.perm)"
            :to="item.routeTo"
            :exact="item.routeTo.name === 'Dashboard'"
            ripple
          >
            {{ item.tabLabel }}
          </v-tab>
        </template>
      </v-tabs>
      <v-spacer />
      <v-toolbar-items>
        <v-btn flat icon @click="drawer = !drawer">
          <v-icon>menu</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-navigation-drawer v-model="drawer" app right temporary>
      <v-toolbar flat>
        <v-list>
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-title class="title"> INGEWA </v-list-tile-title>
              <v-list-tile-sub-title>
                {{ user.first_name }} {{ user.last_name }} ({{ user.username }})
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-toolbar>
      <v-list dense>
        <v-subheader>Benutzer</v-subheader>
        <v-list-tile href="https://www.ingewa.com/" target="_blank">
          <v-list-tile-title
            >INGEWA Webseite
            <v-icon small>launch</v-icon>
          </v-list-tile-title>
        </v-list-tile>
        <v-list-tile>
          <v-list-tile-title
            >INGEWA Hilfe
            <v-icon small>launch</v-icon>
          </v-list-tile-title>
        </v-list-tile>
        <v-list-tile @click="logout">
          <v-list-tile-title>Abmelden</v-list-tile-title>
        </v-list-tile>
        <template v-for="(category, catIndex) in filteredMenuList">
          <v-divider :key="`div-${catIndex}`" />
          <v-subheader :key="`label-${catIndex}`">{{
            category.label
          }}</v-subheader>
          <template v-for="(item, index) in category.children">
            <v-list-tile
              :key="`entry-${catIndex}-${index}`"
              v-if="allow(item.perm)"
              :exact="item.routeTo.name === 'Dashboard'"
              :to="item.routeTo"
              ripple
            >
              <v-list-tile-title>
                {{ item.tabLabel }}
              </v-list-tile-title>
            </v-list-tile>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-content>
      <v-snackbar
        v-model="$root.snackbar.show"
        :color="$root.snackbar.type"
        :timeout="3000"
        right
        top
      >
        {{ $root.snackbar.text }}
        <v-btn dark flat @click="$root.snackbar.show = false">Schließen</v-btn>
      </v-snackbar>
      <v-container fluid v-if="appendContentContainer">
        <v-layout align-start justify-start>
          <slot />
        </v-layout>
      </v-container>
      <slot v-else />
    </v-content>
  </v-app>
</template>
<script>
import authApi from '@/api/authApi'
import { keycloak } from '@/api/keycloakAuth'

export default {
  name: 'AppLayout',
  props: {
    appendContentContainer: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      dialog: false,
      drawer: false,
      navigationEntries: [
        {
          label: 'Gewerbesteuer',
          children: [
            {
              tabLabel: 'Posteingang',
              routeTo: { name: 'Dashboard' },
            },
            {
              tabLabel: 'Zahlungsdaten',
              routeTo: { name: 'PaymentData' },
            },
            {
              tabLabel: 'Stammdaten',
              routeTo: { name: 'MasterData' },
            },
            {
              tabLabel: 'Dokumentarchiv',
              routeTo: { name: 'ArchiveOverview' },
            },
          ],
        },
        {
          label: 'Reporting',
          children: [
            {
              tabLabel: 'Reporting',
              routeTo: { name: 'Reporting' },
              perm: [this.$permissions.closeBiSessionsGenerateReports],
            },
            {
              tabLabel: 'Buchungen',
              routeTo: { name: 'BookingsOverview' },
              perm: [this.$permissions.closeBiSessionsGenerateReports],
            },
            {
              tabLabel: 'GewSt. Zerlegung',
              routeTo: { name: 'Zerlegung' },
              perm: [this.$permissions.closeBiSessionsGenerateReports],
            },
          ],
        },
        {
          label: 'Service',
          children: [
            {
              tabLabel: 'Import',
              routeTo: { name: 'Service.Imports' },
              perm: [
                this.$permissions.changePermissionsOfUser,
                this.$permissions.changeMasterdata,
              ],
            },
            {
              tabLabel: 'Zahlungsjahre',
              routeTo: { name: 'Service.PaymentYearSettings' },
              perm: [
                this.$permissions.changePermissionsOfUser,
                this.$permissions.changeMasterdata,
              ],
            },
            {
              tabLabel: 'Stand der Bearbeitung',
              routeTo: { name: 'Service.StatusStats' },
              perm: [
                this.$permissions.changePermissionsOfUser,
                this.$permissions.changeMasterdata,
              ],
            },
            {
              tabLabel: 'Version',
              routeTo: { name: 'Service.VersionInfo' },
              perm: [
                this.$permissions.changePermissionsOfUser,
                this.$permissions.changeMasterdata,
              ],
            },
            {
              tabLabel: 'Hebesatzkonflikte',
              routeTo: { name: 'Service.Conflicts' },
              perm: [
                this.$permissions.changePermissionsOfUser,
                this.$permissions.changeMasterdata,
              ],
            },
          ],
        },
        {
          label: 'Administration',
          children: [
            {
              tabLabel: 'Benutzer',
              routeTo: { name: 'Admin.Roles' },
              perm: [this.$permissions.changePermissionsOfUser],
            },
            {
              tabLabel: 'Firmen',
              routeTo: { name: 'Admin.Companies' },
              perm: [this.$permissions.changePermissionsOfUser],
            },
            {
              tabLabel: 'Zinsen',
              routeTo: { name: 'Admin.InterestRates' },
              perm: [this.$permissions.changePermissionsOfUser],
            },
            {
              tabLabel: 'Zinszeiträume',
              routeTo: { name: 'Admin.DefaultInterestPeriods' },
              perm: [this.$permissions.changePermissionsOfUser],
            },
            {
              tabLabel: 'Einstellungen',
              routeTo: { name: 'Admin.Settings' },
              perm: [this.$permissions.changePermissionsOfUser],
            },
            {
              tabLabel: 'Dokument Uploads',
              routeTo: { name: 'Admin.AuditTrail' },
              perm: [this.$permissions.changePermissionsOfUser],
            },
            {
              tabLabel: 'Backups',
              routeTo: { name: 'Admin.Backup' },
              perm: [this.$permissions.changePermissionsOfUser],
            },
            {
              tabLabel: 'Logs',
              routeTo: { name: 'Admin.Logs' },
              perm: [this.$permissions.changePermissionsOfUser],
            },
          ],
        },
      ],
    }
  },
  computed: {
    filteredMenuList() {
      return this.navigationEntries
        .map((cat) => {
          const filteredCategory = {
            ...cat,
            children: cat.children.filter((entry) => this.allow(entry.perm)),
          }
          if (filteredCategory.children.length) {
            return filteredCategory
          }
          return null
        })
        .filter(Boolean)
    },
    user() {
      return this.$store.getters.currentUser
    },

    mainMenuEntries() {
      return [
        { tabLabel: 'Posteingang', routeTo: { name: 'Dashboard' } },
        { tabLabel: 'Zahlungsdaten', routeTo: { name: 'PaymentData' } },
        { tabLabel: 'Stammdaten', routeTo: { name: 'MasterData' } },
        {
          tabLabel: 'Reporting',
          routeTo: { name: 'Reporting' },
          perm: [this.$permissions.closeBiSessionsGenerateReports],
        },
        {
          tabLabel: 'Buchungen',
          routeTo: { name: 'BookingsOverview' },
          perm: [this.$permissions.closeBiSessionsGenerateReports],
        },
        {
          tabLabel: 'GewSt. Zerlegung',
          routeTo: { name: 'Zerlegung' },
          perm: [this.$permissions.closeBiSessionsGenerateReports],
        },
        {
          tabLabel: 'Service',
          routeTo: { name: 'Service.Imports' },
          perm: [
            this.$permissions.changePermissionsOfUser,
            this.$permissions.changeMasterdata,
          ],
        },
      ]
    },
    style() {
      const [r, g, b] = this.$store.getters.currentCompanyRGB
      return {
        backgroundColor: `rgba(${r}, ${g}, ${b}, 0.08)`,
      }
    },
  },
  methods: {
    async logout() {
      await authApi.logout()
      keycloak.logout()
    },
    allow(perm = []) {
      if (!perm.length) return true
      for (let i in perm) {
        if (this.$can(perm[i])) return true
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped></style>
