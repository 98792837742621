<template>
  <app-layout>
    <v-layout>
      <v-flex xs12>
        <company-selector />
        <v-card>
          <v-card-title>
            <h3>
              Zahlungsdaten
              {{ $store.getters.currentCompany.name }}
            </h3>
            <v-spacer />
            <v-text-field
              :value="search"
              @input="debounceSearch"
              append-icon="search"
              ref="search"
              label="Suche"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            v-bind="{ loading, headers, items }"
            :pagination.sync="pagination"
            :hide-actions="pagination.totalItems === 0"
            :rows-per-page-items="[10]"
            :total-items="pagination.totalItems"
          >
            <v-progress-linear slot="progress" color="accent" indeterminate />
            <template slot="items" slot-scope="props">
              <tr :key="props.item.id">
                <td>{{ props.item.ags }}</td>
                <td>{{ props.item.municipality }}</td>
                <td>
                  {{ props.item.parent && props.item.parent.municipality }}
                </td>
                <td>
                  {{ props.item.uniformMunicipalRateSince }}
                </td>
                <td>
                  <payment-year-listing :item="props.item" />
                </td>
              </tr>
            </template>
            <template slot="no-data">
              <div class="text-xs-center">
                <template v-if="search.length >= 3"
                  >Es wurden keine Einträge gefunden.</template
                >
                <template v-else>Bitte einen Suchbegriff eingeben.</template>
              </div>
            </template>
            <template slot="pageText" slot-scope="props">
              Einträge {{ props.pageStart }} bis {{ props.pageStop }} von
              insgesamt
              {{ props.itemsLength }}
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </app-layout>
</template>

<script>
import AppLayout from '@/components/AppLayout'
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
import paymentdataApi from '@/api/paymentdataApi'
import CompanySelector from '@/components/CompanySelector'
import PaymentYearListing from '@/pages/paymentData/PaymentYearListing'
import * as Sentry from '@sentry/vue'

export default {
  name: 'PaymentData',
  components: { PaymentYearListing, CompanySelector, AppLayout },
  data() {
    return {
      loading: false,
      search: '',
      rowsPerPageItems: [20],
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
      },
      currentIndex: 1,
      serverData: null,
      headers: [
        { text: 'AGS', sortable: false, value: 'ags', align: 'left' },
        {
          text: 'Gemeindename',
          sortable: false,
          value: 'municipalityName',
          align: 'left',
        },
        {
          text: 'Ortsteil von',
          sortable: false,
          value: 'parent.municipality',
          align: 'left',
        },
        {
          text: 'Einh. Hebesatz (Jahr)',
          sortable: false,
          value: 'uniformMunicipalRateSince',
          align: 'left',
        },
        {
          text: 'Veranlagungsjahre',
          sortable: false,
          value: 'jahr',
          align: 'left',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['currentCompany']),
    items() {
      if (this.serverData) {
        return this.serverData.data
      }
      return []
    },
  },
  watch: {
    'pagination.page': {
      handler: 'loadPage',
      immediate: true,
    },
    'currentCompany.id'() {
      // when company switches keep searchterm and reset fetched data
      this.loadPage(1)
    },
    search() {
      if (this.search.length >= 3) {
        // when the valid searchterm changes, reset fetching data
        this.$router.replace({
          name: 'PaymentData',
          query: { search: this.search },
        })
        this.loadPage(1)
      }
    },
  },
  methods: {
    debounceSearch: debounce(function (value) {
      this.search = value
    }, 200),
    async loadPage(page) {
      if (!this.loading) {
        try {
          this.loading = true
          this.serverData = await paymentdataApi.searchEntries(
            this.$store.getters.currentCompany.id,
            this.search,
            page
          )
          this.pagination.totalItems = this.serverData.total
          this.pagination.page = this.serverData.current_page
          this.pagination.rowsPerPage = this.serverData.per_page
        } catch (error) {
          Sentry.captureException(error)
        } finally {
          this.loading = false
        }
      }
    },
  },
  created() {
    const query = this.$route.query
    if (query && query.search) {
      this.search = query.search
    }
  },
  mounted() {
    this.$refs.search?.focus()
  },
}
</script>

<style scoped></style>
